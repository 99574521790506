import { useEffect, useState } from 'react';

import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { BrowserIntervalCtrl } from '../../../utils/BrowserIntervalCtrl';
import { Loading } from '../../Loading';

const HINTS = [
  'This could take up to 2 minutes...',
  'Waking up the creative goblins in our basement...',
  'Give us a second, we’re on the other line with the President...',
  'Filling the idea pool with rubber ducks...',
  'Rome wasn’t built in a day, we’ll be back in a sec...',
  'Calibrating the fun-o-meter...',
  'Calculating the meaning of life, the universe, and your game...',
  'Hold on, we forgot our keys...',
  'Reaching into our bag of tricks...',
  'We’ll be right back after a word from our sponsors...',
];

const STORAGE_KEY = 'ugc_thinking_hint';

function useThinkingHint(
  intervalMs = 8000,
  trackInSession = true,
  hints = HINTS
) {
  const [hint, setHint] = useState(
    trackInSession ? sessionStorage.getItem(STORAGE_KEY) ?? hints[0] : hints[0]
  );
  const ctrl = useInstance(() => new BrowserIntervalCtrl());

  const play = useLiveCallback(() => {
    ctrl.clear();
    ctrl.set(() => {
      setHint((prev) => {
        const idx = hints.indexOf(prev);
        const nextIdx = (idx + 1) % hints.length;
        const next = hints[nextIdx];
        if (trackInSession) {
          sessionStorage.setItem(STORAGE_KEY, next);
        }
        return next;
      });
    }, intervalMs);
  });

  const pause = useLiveCallback(() => ctrl.clear());

  const playPauseHint = useLiveCallback((op: 'play' | 'pause') => {
    op === 'play' ? play() : pause();
  });

  return { hint, playPauseHint };
}

export function CustomGameThinkingHint(props: {
  containerClassName?: string;
  loadingClassName?: string;
}) {
  const { hint, playPauseHint } = useThinkingHint();

  useEffect(() => {
    playPauseHint('play');
    return () => playPauseHint('pause');
  }, [playPauseHint]);

  return (
    <div
      className={`w-auto h-16 flex items-center justify-center flex-shrink-0 
        bg-black rounded-xl border-2 border-secondary px-4 text-white ${
          props.containerClassName ?? ''
        }`}
    >
      <Loading
        imgClassName={props.loadingClassName}
        text={hint || 'Thinking...'}
      />
    </div>
  );
}
