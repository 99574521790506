import { uuid4 } from '@sentry/utils';
import Uppy, { type UppyEventMap } from '@uppy/core';
import DropTarget from '@uppy/drop-target';
import XHRUpload from '@uppy/xhr-upload';
import { useEffect, useMemo } from 'react';
import { proxy } from 'valtio';

import {
  type DtoGamePackUGCFile,
  type DtoUploadUGCFileResponse,
  EnumsMediaScene,
} from '@lp-lib/api-service-client/public';

import config from '../../../config';
import { apiService } from '../../../services/api-service';
import { BrowserIntervalCtrl } from '../../../utils/BrowserIntervalCtrl';
import { err2s } from '../../../utils/common';
import { createProvider } from '../../../utils/createProvider';
import { Emitter, type EmitterListener } from '../../../utils/emitter';
import { getToken } from '../../../utils/getToken';
import {
  markSnapshottable,
  useSnapshot,
  type ValtioSnapshottable,
  ValtioUtils,
} from '../../../utils/valtio';
import { type Message, type UGCFile } from './types';
import { log, UGCUtils } from './utils';

type State = {
  packId: string | null;
  files: UGCFile[];
  draging: boolean;
};

export type Events = {
  message: (message: Message) => void;
};

class UGCFileManager implements EmitterListener<Events> {
  readonly uppy: Uppy;
  private _state = markSnapshottable(proxy<State>(this.initialState()));
  private syncer = new BrowserIntervalCtrl();
  private emitter = new Emitter<Events>();
  on = this.emitter.on.bind(this.emitter);
  off = this.emitter.off.bind(this.emitter);

  constructor(
    readonly logger = log,
    private deps = {
      getUGCFiles: apiService.gamePack.getUGCFiles.bind(apiService.gamePack),
      deleteUGCFile: apiService.gamePack.deleteUGCFile.bind(
        apiService.gamePack
      ),
    }
  ) {
    this.uppy = new Uppy({
      logger,
      autoProceed: true,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: 300 * 1024 * 1024,
        allowedFileTypes: UGCUtils.AllowedFileTypes,
      },
    });
  }

  get state(): ValtioSnapshottable<State> {
    return this._state;
  }

  init(
    packId: string,
    files: DtoGamePackUGCFile[],
    baseUrl = config.api.baseUrl
  ) {
    if (this._state.packId) return;
    this.uppy.use(XHRUpload, {
      id: `${packId}-uploader`,
      endpoint: `${baseUrl}/game-packs/${packId}/ugc-files/upload`,
      method: 'post',
      formData: false,
      headers: (file) => {
        const extraHeaders: Record<string, string> = {
          authorization: `Bearer ${getToken()}`,
          'x-lp-scene': EnumsMediaScene.MediaSceneGamePackUgcFile,
        };
        if (file.type) {
          extraHeaders['Content-Type'] = file.type;
        }
        extraHeaders['x-lp-filename'] = file.name;
        return extraHeaders;
      },
      timeout: 1800 * 1000, // 30 Mins
      limit: 10,
    });
    for (const file of files) {
      this._state.files.push({ remote: true, file, localFileId: null });
      this.maybeOutstanding(file);
    }
    this._state.packId = packId;
    const hasPendingUploadFiles = this._state.files.some(
      (f) => !f.remote && f.status === 'none'
    );
    if (hasPendingUploadFiles) {
      this.uppy.upload().catch((error) => {
        this.logger.error('failed to upload files from init', error);
      });
    }
  }

  get inited() {
    return this._state.packId !== null;
  }

  private findFileIndex(fileId: string) {
    return this._state.files.findIndex((f) => f.file.id === fileId);
  }

  watch() {
    const onFileAdded: UppyEventMap['file-added'] = (file) => {
      this._state.files.push({
        remote: false,
        file,
        status: 'none',
      });
    };

    const onFileRemoved: UppyEventMap['file-removed'] = (file) => {
      this.logger.info('file removed', {
        fileId: file.id,
        fileName: file.name,
      });
    };

    const onUploadProgress: UppyEventMap['upload-progress'] = (updatedFile) => {
      const idx = this.findFileIndex(updatedFile.id);
      if (idx === -1 || this._state.files[idx].remote) return;
      this._state.files[idx].file = updatedFile;
      this._state.files[idx].status = 'uploading';
    };

    const onUploadSuccess: UppyEventMap['upload-success'] = (
      updatedFile,
      response
    ) => {
      const idx = this.findFileIndex(updatedFile.id);
      if (idx === -1 || this._state.files[idx].remote) return;
      const localFileId = this._state.files[idx].file.id;
      const body = response.body as DtoUploadUGCFileResponse;
      this._state.files[idx] = {
        remote: true,
        file: body.file,
        localFileId,
      };
      this.maybeOutstanding(body.file);
      this.logger.info('file upload success', {
        file: body.file,
      });
    };

    const onUploadError: UppyEventMap['upload-error'] = (
      updatedFile,
      error,
      response
    ) => {
      const idx = this.findFileIndex(updatedFile.id);
      if (idx === -1 || this._state.files[idx].remote) return;
      this._state.files[idx].status = 'failed';
      this.logger.error('file upload failed', error, {
        file: updatedFile,
        response,
      });
    };

    const onError: UppyEventMap['error'] = (error) => {
      this.emitter.emit('message', {
        type: 'error',
        detail: err2s(error) ?? '',
      });
    };

    const onRestrictionFailed: UppyEventMap['restriction-failed'] = (
      _,
      error
    ) => {
      this.emitter.emit('message', {
        type: 'error',
        detail: err2s(error) ?? '',
      });
    };

    this.uppy.on('file-added', onFileAdded);
    this.uppy.on('file-removed', onFileRemoved);
    this.uppy.on('upload-progress', onUploadProgress);
    this.uppy.on('upload-success', onUploadSuccess);
    this.uppy.on('upload-error', onUploadError);
    this.uppy.on('restriction-failed', onRestrictionFailed);
    this.uppy.on('error', onError);

    return () => {
      this.uppy.off('file-added', onFileAdded);
      this.uppy.off('file-removed', onFileRemoved);
      this.uppy.off('upload-progress', onUploadProgress);
      this.uppy.off('upload-success', onUploadSuccess);
      this.uppy.off('upload-error', onUploadError);
      this.uppy.off('restriction-failed', onRestrictionFailed);
      this.uppy.off('error', onError);
    };
  }

  private maybeOutstanding(file: DtoGamePackUGCFile) {
    if (UGCUtils.IsOutstandingUGCFile(file)) {
      this.syncer.clear();
      this.syncer.set(this.syncRemoteFiles.bind(this), 10000);
    }
  }

  async deleteFile(fileId: string) {
    const idx = this.findFileIndex(fileId);
    if (idx === -1) return;
    const file = this._state.files[idx];
    this.logger.info('deleting file', {
      fileName: file.file.name,
      fileId: fileId,
    });
    // immediately remove the file from the list for fast UI feedback
    // if the file is failed to delete, it will appear again next time
    this._state.files.splice(idx, 1);
    if (file.remote) {
      if (!this._state.packId) return;
      if (file.localFileId) {
        this.uppy.removeFile(file.localFileId);
      }
      await this.deps.deleteUGCFile(this._state.packId, fileId);
    } else {
      this.uppy.removeFile(file.file.id);
    }
  }

  async downloadFile(fileId: string) {
    const idx = this.findFileIndex(fileId);
    if (idx === -1) return;
    const file = this._state.files[idx];
    if (!file.remote || !this._state.packId) return;
    try {
      await UGCUtils.DownloadFile(file.file);
    } catch (error) {
      this.logger.error('failed to download file', error);
    }
  }

  enableDrapDrop(el: HTMLElement) {
    this.uppy.use(DropTarget, {
      id: uuid4(),
      target: el,
      onDragOver: (event) => {
        event.preventDefault();
        this._state.draging = true;
      },
      onDragLeave: (event) => {
        event.preventDefault();
        this._state.draging = false;
      },
      onDrop: (event) => {
        event.preventDefault();
        this._state.draging = false;
      },
    });
    return () => {
      const instance = this.uppy.getPlugin('DropTarget');
      if (!instance) return;
      this.uppy.removePlugin(instance);
    };
  }

  emitMessage(message: string) {
    this.emitter.emit('message', { type: 'success', detail: message });
  }

  private async syncRemoteFiles() {
    if (!this._state.packId) return;
    try {
      this.logger.info('syncing remote files');
      const resp = await this.deps.getUGCFiles(this._state.packId);
      for (const file of resp.data.files) {
        const idx = this.findFileIndex(file.id);
        if (
          !this._state.files[idx] ||
          !this._state.files[idx].remote ||
          !UGCUtils.IsOutstandingUGCFile(this._state.files[idx].file)
        ) {
          continue;
        }
        ValtioUtils.update(this._state.files[idx].file, file);
      }
      const allGood = this._state.files.every((f) =>
        f.remote ? !UGCUtils.IsOutstandingUGCFile(f.file) : true
      );
      if (allGood) {
        this.syncer.clear();
      }
    } catch (error) {
      this.logger.error('failed to sync remote files', error);
    }
  }

  initialState(): State {
    return {
      packId: null,
      files: [],
      draging: false,
    };
  }
}

const { Provider, useCreatedContext } =
  createProvider<UGCFileManager>('UGCFileManager');

export function useUGCFileManager(): UGCFileManager {
  return useCreatedContext();
}

export function useUGCFiles() {
  const fileman = useUGCFileManager();
  return useSnapshot(fileman.state).files as UGCFile[];
}

export function useIsUGCFileManagerDraging() {
  const fileman = useUGCFileManager();
  return useSnapshot(fileman.state).draging;
}

export function UGCFileManagerProvider(props: { children?: React.ReactNode }) {
  const instance = useMemo(() => new UGCFileManager(), []);

  useEffect(() => {
    return instance.watch();
  }, [instance]);

  return <Provider value={instance}>{props.children}</Provider>;
}
