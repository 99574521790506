import { type ReactNode } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { ModalWrapper } from '../../ConfirmCancelModalContext/ModalWrapper';

export function UGCDiffRejectButton(props: { onClick?: () => void }) {
  return (
    <button
      type='button'
      onClick={props.onClick}
      className={`btn-delete w-26 h-9`}
    >
      <p>Reject</p>
    </button>
  );
}

export function UGCDiffAcceptButton(props: {
  onClick?: () => void;
  disabled?: boolean;
  running?: boolean;
}) {
  return (
    <button
      type='button'
      onClick={props.onClick}
      className={`btn-success w-26 h-9 flex items-center justify-center gap-2`}
      disabled={props.disabled}
    >
      <p>Accept</p>
    </button>
  );
}

export function UGCDiffOldValue(props: { value: string; className?: string }) {
  return (
    <p
      className={`text-white bg-lp-red-002 text-sms bg-opacity-60 ${
        props.className ?? ''
      }`}
    >
      {props.value}
    </p>
  );
}

export function UGCDiffNewValue(props: { value: string; className?: string }) {
  return (
    <p
      className={`text-white bg-lp-green-003 text-sms bg-opacity-60 ${
        props.className ?? ''
      }`}
    >
      {props.value}
    </p>
  );
}

export function UGCTextDiff(props: {
  oldValue: string;
  newValue: string;
  className?: string;
}) {
  return (
    <div className='flex flex-col gap-5'>
      <UGCDiffOldValue value={props.oldValue} className={props.className} />
      <UGCDiffNewValue value={props.newValue} className={props.className} />
    </div>
  );
}

export function useOpenUGCDiffModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback(
    (props: { containerClassName?: string; children?: ReactNode }) => {
      return triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName={props.containerClassName ?? 'w-120'}
            borderStyle='gray'
          >
            <section className='flex flex-col items-center justify-center gap-5 p-4'>
              {props.children}
              <footer className='flex justify-center items-center gap-5'>
                <UGCDiffRejectButton onClick={p.internalOnCancel} />
                <UGCDiffAcceptButton onClick={p.internalOnConfirm} />
              </footer>
            </section>
          </ModalWrapper>
        ),
      });
    }
  );
}
