export function StopIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='14'
      fill='none'
      viewBox='0 0 15 14'
    >
      <path
        fill='#fff'
        stroke='#fff'
        strokeWidth='1.5'
        d='M3.41 3.625c0-.207.168-.375.375-.375h6.75c.207 0 .375.168.375.375v6.75a.375.375 0 01-.375.375h-6.75a.375.375 0 01-.375-.375v-6.75z'
      ></path>
    </svg>
  );
}
