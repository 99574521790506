import { z } from 'zod';

export const jeopardyBoardSchema = z
  .object({
    board: z
      .object({
        categories: z
          .array(
            z
              .object({
                id: z.string().describe('Category ID'),
                name: z
                  .string()
                  .describe(
                    'The name of the category. This is visible throughout the game.'
                  ),
                clues: z
                  .array(
                    z
                      .object({
                        id: z.string().describe('Clue ID'),
                        question: z
                          .string()
                          .describe(
                            "The prompt for this clue. This is visible to users when this clue is selected. It's traditional in Jeopardy to phrase this as a statement (ex: The third planet from the sun)."
                          ),
                        answer: z
                          .string()
                          .describe(
                            'The correct response for this clue. This is visible to users when they are judging a user response, and visible to all users on answer reveal. This is traditionally phrased as a question (ex: What is Earth?).'
                          ),
                        value: z
                          .number()
                          .describe(
                            'The award for this clue. This will be shown as a monetary value, but will be awarded as points.'
                          ),
                      })
                      .strict()
                  )
                  .describe('The knowledge clues belong to the category'),
              })
              .strict()
          )
          .describe('A list of Jeopardy categories'),
      })
      .strict()
      .describe('The Jeopardy Board'),
  })
  .strict();
