import { type AxiosResponse } from 'axios';
import React, { type ReactNode, useEffect, useRef, useState } from 'react';

import { type ModelsTTSRenderSettings } from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { TimeUtils } from '../../utils/time';
import { PlayIcon } from '../icons/PlayIcon';
import { Loading } from '../Loading';
import {
  type TTSCustomPrevievProps,
  TTSCustomPreview,
} from './TTSCustomPreview';

function Widget(props: {
  audioRef: React.MutableRefObject<HTMLAudioElement | null>;
  handlePreview: () => Promise<void>;
  disabled: boolean;
  isGenerating: boolean;
}) {
  const { audioRef, handlePreview, disabled, isGenerating } = props;
  const [currentTime, setCurrentTime] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const duration = audioRef.current?.duration || 0;
  const percentage = duration ? (currentTime / duration) * 100 : 0;

  const handleClickBar = (event: React.MouseEvent) => {
    if (!ref.current || !duration) return;

    const rect = ref.current.getBoundingClientRect();
    const barWidth = ref.current.clientWidth;
    const clickedX = event.clientX - rect.left;
    const clickedTime = (duration * clickedX) / barWidth;
    if (audioRef.current) {
      audioRef.current.currentTime = clickedTime;
    }
    setCurrentTime(clickedTime);
  };

  useEffect(() => {
    const el = audioRef.current;
    if (!el) return;
    const abort = new AbortController();
    el.addEventListener(
      'timeupdate',
      () => {
        setCurrentTime(el.currentTime);
      },
      { signal: abort.signal }
    );
    return () => abort.abort();
  });

  return (
    <div className='w-full flex items-center gap-4'>
      <button
        type='button'
        className={
          'btn w-6 h-6 rounded-full bg-white text-black flex items-center justify-center flex-shrink-0'
        }
        onClick={handlePreview}
        disabled={disabled}
      >
        <div className='w-3.5 h-3.5'>
          {isGenerating ? (
            <Loading text='' imgClassName='w-full h-full object-contain' />
          ) : (
            <PlayIcon className='w-full h-full text-black' />
          )}
        </div>
        <audio ref={audioRef} className='hidden' />
      </button>
      <div
        ref={ref}
        className={`w-full h-0.75 ${
          duration > 0 ? 'cursor-pointer' : ''
        } flex items-center bg-lp-gray-005`}
        onClick={handleClickBar}
      >
        <div
          className='relative bg-white h-0.75 flex items-center'
          style={{
            width: `${percentage}%`,
          }}
        >
          <div className='absolute -right-0.75 w-2.5 h-2.5 bg-[#D9D9D9] rounded-full'></div>
        </div>
      </div>
      <div className='my-1 flex items-center text-3xs text-white'>
        <span>{TimeUtils.FormatElapsedTimeSecs(currentTime)}</span>
        <span>/</span>
        <span className='text-secondary'>
          {TimeUtils.FormatElapsedTimeSecs(duration)}
        </span>
      </div>
      <audio ref={audioRef} className='hidden' />
    </div>
  );
}

export function TTSPreview(
  props: TTSCustomPrevievProps & {
    disabled?: boolean;
  }
) {
  const { disabled, ...rest } = props;
  return (
    <TTSCustomPreview {...rest}>
      {(widgetProps) => (
        <Widget
          {...widgetProps}
          disabled={props.disabled ?? widgetProps.disabled}
        />
      )}
    </TTSCustomPreview>
  );
}

export function MultilineCardTTSEditor(props: {
  label: ReactNode;
  value?: string | null | undefined;
  initialValue?: string | null;
  onChange: (value: string) => void;
  onBeforeRender?: (value: Nullable<string>) => Promise<Nullable<string>>;
  settings: ModelsTTSRenderSettings | null | undefined;
  defaultValue?: string | null;
  disabled?: boolean;
  render?: (script: string) => Promise<AxiosResponse<Blob>>;
}) {
  const handleChange = useLiveCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInternalValue(e.target.value);
      props.onChange(e.target.value);
    }
  );

  const [internalValue, setInternalValue] = useState<string | null | undefined>(
    props.value ?? props.initialValue
  );

  return (
    <div className='w-full h-full flex flex-col gap-2 px-6 py-4.5 bg-modal rounded-xl border border-secondary'>
      <div className='text-white font-bold'>{props.label}</div>
      <textarea
        className='field m-0 w-full h-full resize-none py-2'
        value={props.value ?? undefined}
        defaultValue={props.initialValue ?? undefined}
        onChange={handleChange}
        placeholder={props.defaultValue ?? undefined}
        disabled={props.disabled}
      />
      <TTSPreview
        script={props.value || internalValue || props.defaultValue}
        settings={props.settings}
        onBeforeRender={props.onBeforeRender}
        disabled={props.disabled}
        render={props.render}
      />
    </div>
  );
}
